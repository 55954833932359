<template>
  <section id="partners-full" class="partners-full" v-if="!visibility || content.items.length">
    <div class="partners-full__header">
      <div class="partners-full__title" v-if="content.title" v-html="content.title" data-aos="custom-appearance" data-aos-duration="1000"></div>
      <div class="partners-full__description" v-if="content.desc" v-html="content.desc" data-aos="custom-appearance" data-aos-duration="800" data-aos-delay="200"></div>
    </div>
    <ul class="partners-full__list">
      <li class="partners-full__item" v-for="(item, index) in content.items" :key="item.desc">
        <CardPartners @popup="openPopup(item)" :content="item" :reverse="index % 2" />
      </li>
    </ul>
  </section>
</template>

<script>
import CardPartners from "@/components/block/CardPartners.vue";

export default {
  components: {
    CardPartners,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.partners_full,
    };
  },
  methods: {
    openPopup(item) {
      if(item.url != "") {
        window.open(item.url, '_blank');
        return;
      }

      if (item.button_metka) {
        this.$metrika.reachGoal(item.button_metka);
      }

      this.$store.commit("updateOrderPopup", {
        status: true,
        data: item.popup,
        name: null,
      });
    }
  }
};
</script>

<style>
.partners-full {
  margin-bottom: 150px;
}

.partners-full__header {
  margin-bottom: 70px;
}

.partners-full__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  color: var(--title2-color-white);
  font-weight: 400;
  text-align: center;
}

.partners-full__description {
  max-width: 500px;
  margin: 0 auto;

  font-family: var(--subtitle3-fonts-family);
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  color: var(--subtitle3-color-white);
  font-weight: 400;
  text-align: center;
}

@media (max-width: 1599.98px) {
  .partners-full {
    margin-bottom: 130px;
  }

  .partners-full__header {
    margin-bottom: 70px;
  }

  .partners-full__title {
    font-size: 40px;
  }

  .partners-full__description {
    font-size: 12px;
  }

  .partners-full__description {
    max-width: 450px;
  }
}

@media (max-width: 1199.98px) {
  .partners-full {
    margin-bottom: 120px;
  }

  .partners-full__header {
    margin-bottom: 55px;
  }

  .partners-full__title {
    font-size: 32px;
  }

  .partners-full__description {
    max-width: 370px;
  }
}

@media (max-width: 991.98px) {
  .partners-full {
    margin-bottom: 110px;
  }

  .partners-full__header {
    margin-bottom: 50px;
  }

  .partners-full__title {
    font-size: 28px;
  }
}

@media (max-width: 767.98px) {
  .partners-full {
    margin-bottom: 90px;
  }

  .partners-full__header {
    margin-bottom: 45px;
  }

  .partners-full__title {
    font-size: 26px;
  }
}
</style>