<template>
  <ul :class="classObject">
    <li class="social__item" v-for="item in content" :key="item.name_social">
      <a class="social__link" :href="item.link_social" target="_blank">
        {{ item.name_social }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["classSocial", "content"],
  data() {
    return {
      classObject: {
        [this.classSocial]: this.classSocial,
        social: true,
      },
    };
  },
};
</script>

<style>
.social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.social__item {
  margin-right: 22px;
  margin-bottom: 5px;
}

.social__item:last-child {
  margin-right: 0;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px 8px;

  background-color: var(--backgraund-background-bg-sm); /* Переменная CSS*/
  transition: background-color 0.3s ease-in;

  color: #ffffff;
}

.social__link:hover {
  background-color: var(--backgraund-background-bg-sm-hover); /* Переменная CSS*/
}

.social__icon {
  display: block;
}

.social__icon path {
  fill: #ffffff;
  transition: fill 0.3s ease-in;
}

.social__link:hover path {
  fill: #ffffff;
}

@media (max-width: 1599.98px) {
  .social__item {
    margin-right: 20px;
  }

  .social__icon-vk {
    width: 14px;
    height: 11px;
  }

  .social__icon-in {
    width: 9px;
    height: 10px;
  }

  .social__icon-fb {
    width: 12px;
    height: 11px;
  }
}

@media (max-width: 1199.98px) {
  .social__item {
    margin-right: 15px;
  }

  .social__icon-vk {
    width: 12px;
    height: 9px;
  }

  .social__icon-in {
    width: 7px;
    height: 8px;
  }

  .social__icon-fb {
    width: 10px;
    height: 9px;
  }
}
</style>
