import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state() {
    return {
      preloader: true,
      data: null,
      statusFormPopup: false,
      servicesPopup: {
        status: false,
        data: null,
      },
      doctorsPopup: {
        status: false,
        data: null,
      },
      orderPopup: {
        status: false,
        data: null,
        name: null,
      },
      questionPopup: {
        status: false,
        data: null,
      },
    };
  },
  mutations: {
    preloaderOff(state) {
      state.preloader = false;
    },
    updateData(state, preload) {
      state.data = preload;
    },
    updateServicesPopup(state, preload) {
      state.servicesPopup = preload;
    },
    updateDoctorsPopup(state, preload) {
      state.doctorsPopup = preload;
    },
    updateOrderPopup(state, preload) {
      state.orderPopup = preload;
    },
    updateQuestionPopup(state, preload) {
      state.questionPopup = preload;
    },
    updateStatusFormPopop(state) {
      state.statusFormPopup = !state.statusFormPopup;
    },
  },
  actions: {
    async loadingData(context) {
      const [menuResponse, contentResponse, settingsResponse, mediaResponse] =
        await Promise.all([
          axios.get(
            `${window.settingsApp.api}/wp-json/wp-api-menus/v2/menus/2`
          ),
          axios.get(`${window.settingsApp.api}/wp-json/wp/v2/pages/186`),
          axios.get(`${window.settingsApp.api}/wp-json/wp/v2/pages/188`),
          axios.get(
            `${window.settingsApp.api}/wp-json/wp/v2/media?parent=186&per_page=100`
          ),
        ]);

      let transformMedia = {};
      mediaResponse.data.forEach((item) => (transformMedia[item.id] = item));

      context.commit("updateData", {
        menuResponse: menuResponse.data.items,
        contentResponse: contentResponse.data.acf,
        settingsResponse: settingsResponse.data.acf,
        mediaResponse: transformMedia,
      });

      setTimeout(() => context.commit("preloaderOff"), 2000);
    },

    async submitForm(context, payload) {

      let bodyRequest = new FormData();

      for (let key in payload.data) {
        if (key != "id") {
          bodyRequest.append(key, payload.data[key]);
        }
      }

      setTimeout(() => {
        context.commit("updateStatusFormPopop");
      }, 400);

      axios({
        method: "post",
        url: `${window.settingsApp.api}/wp-json/contact-form-7/v1/contact-forms/${payload.data.id}/feedback`,
        data: bodyRequest,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(function (resulte) {
        if (resulte.data.status === "mail_sent") {
          if (payload.mertica.metka) {
            payload.mertica.reach(payload.mertica.metka);
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
  },
});
