<template>
  <button :class="classObject">
    <span class="button-text button-text--one"><slot /></span>
    <span class="button-text button-text--two"><slot /></span>
  </button>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        button: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 154px;
  padding: 13px 35px;
  border: 1px solid var(--buttom-color-border-white);
  border-radius: var(--buttom-border-radius);

  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  color: var(--buttom-color-white);

  background-color: var(--buttom-background-white);

  overflow: hidden;
  outline: none !important;
  appearance: none;
  cursor: pointer;
  transition: background-color 1s ease-in, color 0.3s linear, border-color 0.5s linear, color 0.4s ease-in;
}

.button:hover {
  background-color: var(--buttom-background-white-hover);
}

.dark .button {
  color: var(--buttom-color-dark);

  border: 2px solid var(--buttom-color-border-dark);

  background-color: var(--buttom-background-dark);
}

.dark .button:hover {
  color: #090909;
  background-color: var(--buttom-background-dark-hover);
  border-color: var(--buttom-background-dark-hover);
}

.button-text {
  transition: transform 0.4s ease;
}

.button-text--one {
  position: static;
  transform: translateY(0);
}

.button-text--two {
  position: absolute;
  transform: translateY(70px);
}

.button:hover .button-text--two {
  transform: translateY(0);
}

.button:hover .button-text--one {
  transform: translateY(-70px);
}

@media (max-width: 1599.98px) {
  .button {
    min-width: 134px;
    padding: 10px 30px;

    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .button {
    min-width: 119px;
    padding: 10px 28px;

    font-size: 12px;
  }
}
</style>
