<template>
  <input
    :class="classObject"
    :v-input-mask="vInputMask"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: ["parentClass", "vInputMask", "modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      classObject: {
        ["input-text"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.input-text {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-color-border-white);

  background-color: var(--input-background-white);

  color: var(--input-color-white);

  appearance: none;
  -webkit-appearance: none;

  outline: none !important;
  transition: border-color 0.5s ease-in, color 0.5s ease-in,
    background-color 0.5s ease-in;
  box-shadow: none;
}

.dark .input-text {
  border-color: var(--input-color-border-dark);

  background-color: var(--input-background-dark);

  color: var(--input-color-dark);
}

.input-text:focus,
.input-text:hover {
  border-color: var(--input-border_2);

  background-color: var(--input-border_2);
}

.dark .input-text:focus,
.dark .input-text:hover {
  border-color: var(--input-color-border-dark-focus);

  background-color: var(--input-background-dark-focus);
}

.error .input-text,
.error .input-text:focus,
.error .input-text:hover {
  border: 1px solid #d92641;
  transition-duration: 0s;
}

.error .input_wrapper {
  position: relative;
}

.error .input_wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;

  width: 22px;
  height: 22px;

  background-image: url("@/assets/img/error.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transform: translateY(-50%);
}

@media screen and (max-width: 1599.98px) {
  .input-text {
    padding: 9px;
  }
}

@media screen and (max-width: 1199.98px) {
  .input-text {
    padding: 7px;
  }

  .error .input_wrapper::after {
    width: 17px;
    height: 17px;
  }
}
</style>
