<template>
  <div class="link-panel">
    <div class="link-panel__wraper">
      <a class="link-panel__item"  v-scroll-to="'#top'" href="#">
        <svg width="20" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.71107 0.512417C8.44415 -0.170806 9.55581 -0.170806 10.2889 0.512417L17.3604 7.10303C17.7674 7.48246 18 8.02346 18 8.59118V17.9994C18 19.1043 17.1365 20 16.0714 20H12.8571C11.7921 20 10.9286 19.1043 10.9286 17.9994V13.3314C10.9286 12.963 10.6407 12.6646 10.2857 12.6646H7.71429C7.35925 12.6646 7.07143 12.963 7.07143 13.3314V17.9994C7.07143 19.1043 6.20798 20 5.14286 20H1.92857C0.863447 20 0 19.1043 0 17.9994V8.59118C0 8.02346 0.232534 7.48246 0.639643 7.10303L7.71107 0.512417ZM9.42969 1.50452C9.18527 1.27678 8.81472 1.27678 8.57035 1.50452L1.49892 8.09513C1.36323 8.22161 1.28571 8.40194 1.28571 8.59118V17.9994C1.28571 18.3677 1.57353 18.6663 1.92857 18.6663H5.14286C5.49789 18.6663 5.78571 18.3677 5.78571 17.9994V13.3314C5.78571 12.2264 6.64916 11.3308 7.71429 11.3308H10.2857C11.3508 11.3308 12.2143 12.2264 12.2143 13.3314V17.9994C12.2143 18.3677 12.5022 18.6663 12.8571 18.6663H16.0714C16.4264 18.6663 16.7143 18.3677 16.7143 17.9994V8.59118C16.7143 8.40194 16.6368 8.22161 16.5011 8.09513L9.42969 1.50452Z" fill="#FFFFFF"/>
        </svg>
      </a>
      <a class="link-panel__item" :href="'mailto:' + content.header_mail">
        <svg width="19" height="18" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.2955 1.17647C13.3453 1.17647 11.7644 2.75743 11.7644 4.70767C11.7644 6.65789 13.3453 8.23885 15.2955 8.23885C15.8366 8.23885 16.3427 8.11532 16.8052 7.89467C17.0984 7.75478 17.4495 7.87908 17.5894 8.17229C17.7293 8.4655 17.6049 8.8166 17.3118 8.95649C16.6991 9.24881 16.0205 9.41532 15.2955 9.41532C12.6955 9.41532 10.5879 7.30763 10.5879 4.70767C10.5879 2.10769 12.6955 0 15.2955 0C17.894 0 20.0007 2.10515 20.0032 4.70296V4.70588V5.29174C20.0032 6.26734 19.2124 7.05822 18.2367 7.05822C17.6971 7.05822 17.2139 6.81619 16.8899 6.43475C16.4704 6.82213 15.9098 7.05882 15.2938 7.05882C13.9942 7.05882 12.9408 6.00537 12.9408 4.70588C12.9408 3.40638 13.9942 2.35294 15.2938 2.35294C15.744 2.35294 16.1647 2.47941 16.5224 2.69877C16.6147 2.49482 16.82 2.35294 17.0585 2.35294C17.3833 2.35294 17.6467 2.6163 17.6467 2.94117V5.29174C17.6467 5.61759 17.9108 5.88175 18.2367 5.88175C18.5626 5.88175 18.8267 5.61759 18.8267 5.29174V4.70767C18.8267 2.75743 17.2458 1.17647 15.2955 1.17647ZM14.1173 4.70588C14.1173 5.35562 14.644 5.88235 15.2938 5.88235C15.9435 5.88235 16.4702 5.35562 16.4702 4.70588C16.4702 4.05614 15.9435 3.52941 15.2938 3.52941C14.644 3.52941 14.1173 4.05614 14.1173 4.70588Z" fill="#FFFFFF"/>
          <path d="M18.8235 13.2664V9.41228C18.4615 9.68421 18.067 9.91534 17.647 10.0989V13.2664C17.647 14.2693 16.819 15.1087 15.7647 15.1087H9.97752L4.94708 18.8222L4.94524 18.8232L4.94117 18.8236L4.94009 15.1087H3.05882C2.0045 15.1087 1.17647 14.2693 1.17647 13.2664V5.37164C1.17647 4.36888 2.0045 3.5295 3.05882 3.5295H9.5294C9.61246 3.12032 9.73799 2.72656 9.90117 2.35303H3.05882C1.36948 2.35303 0 3.70451 0 5.37164V13.2664C0 14.9336 1.36948 16.2851 3.05882 16.2851H3.76396L3.7647 18.8392C3.7647 19.0897 3.84693 19.3336 3.99907 19.5344C4.38823 20.0478 5.1255 20.1528 5.64582 19.7688L10.3647 16.2851H15.7647C17.454 16.2851 18.8235 14.9336 18.8235 13.2664Z" fill="#FFFFFF"/>
        </svg>
      </a>
      <a class="link-panel__item" :href="'tel:' + content.header_phone_link">
        <svg width="16" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.2489 0.106805L3.30968 0.376904C1.60142 0.868167 0.34677 2.25995 0.0932299 3.94489C-0.295946 6.53139 0.57452 9.55464 2.67189 13.0205C4.76485 16.4791 7.06856 18.7078 9.6046 19.6828C11.2684 20.3223 13.1703 19.978 14.4728 18.8012L15.1841 18.1585C16.1375 17.2971 16.2756 15.8956 15.5073 14.8797L13.7295 12.529C13.2373 11.878 12.3653 11.5982 11.5608 11.8329L8.87322 12.6168L8.80376 12.6298C8.50746 12.6711 7.82437 12.0596 6.97237 10.6517C6.08105 9.17879 5.89632 8.31756 6.14241 8.09554L7.50971 6.8791C8.53446 5.96744 8.83718 4.53456 8.26266 3.31534L7.39585 1.47569C6.85606 0.330131 5.50935 -0.255679 4.2489 0.106805ZM6.20058 1.98834L7.06742 3.82799C7.41185 4.55899 7.23034 5.4181 6.61595 5.9647L5.24531 7.18413C4.36846 7.97523 4.65889 9.32929 5.83746 11.2769C6.94639 13.1093 7.95808 14.0149 9.04515 13.8579L9.20805 13.8246L11.9438 13.0285C12.2121 12.9503 12.5027 13.0437 12.6668 13.2606L14.4446 15.6113C14.8287 16.1192 14.7597 16.82 14.283 17.2506L13.5717 17.8934C12.6414 18.734 11.2828 18.9799 10.0945 18.5231C7.86913 17.6676 5.76605 15.633 3.8068 12.3954C1.84356 9.15113 1.04901 6.39156 1.39042 4.12255C1.57151 2.91903 2.46771 1.9249 3.68789 1.57399L4.6271 1.30389C5.25733 1.12265 5.93069 1.41556 6.20058 1.98834Z" fill="#FFFFFF"/>
        </svg>
      </a>
      <a class="link-panel__item" target="_blank" :href="content.header_address_link">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.1451 8.74999C12.1451 10.8211 10.5142 12.5 8.50224 12.5C6.49035 12.5 4.85938 10.8211 4.85938 8.74999C4.85938 6.67893 6.49035 5 8.50224 5C10.5142 5 12.1451 6.67893 12.1451 8.74999ZM10.9308 8.74999C10.9308 7.36928 9.84354 6.25 8.50224 6.25C7.16097 6.25 6.07366 7.36928 6.07366 8.74999C6.07366 10.1307 7.16097 11.25 8.50224 11.25C9.84354 11.25 10.9308 10.1307 10.9308 8.74999Z" fill="#FFFFFF"/>
          <path d="M14.5133 14.9437C17.8328 11.5251 17.8328 5.9825 14.5133 2.56394C11.1939 -0.854645 5.81199 -0.854645 2.49253 2.56394C-0.826936 5.9825 -0.826936 11.5251 2.49253 14.9437L4.33949 16.8172L6.82004 19.2986L6.98133 19.4458C7.92213 20.2308 9.29976 20.1818 10.1861 19.2988L13.1443 16.3363L14.5133 14.9437ZM3.34828 3.44523C6.19512 0.513402 10.8108 0.513402 13.6576 3.44523C16.4315 6.3019 16.5026 10.888 13.8709 13.8334L13.6576 14.0624L12.0532 15.6923L9.34457 18.4031L9.23176 18.5026C8.80154 18.8373 8.2046 18.8372 7.77452 18.5025L7.6616 18.403L4.04202 14.7712L3.34828 14.0624L3.13491 13.8334C0.503316 10.888 0.574437 6.3019 3.34828 3.44523Z" fill="#FFFFFF"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.$store.state.data.contentResponse.header,
    };
  },
};
</script>

<style>
.link-panel {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 42px;
  border-radius: 10px 10px 0 0;
  margin: 0 auto;

  background-color: var(--backgraund-background-bg-ctrl);
  backdrop-filter: blur(15px);
}

.link-panel__wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 255px;
}

.link-panel svg {
  display: block;
} 

@media (max-width: 991.98px) {
  .link-panel {
    display: flex;
  }
}
</style>