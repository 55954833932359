export function validation(value, quantity) {
  if (quantity) {
    if (value !== quantity) {
      return false;
    }
  } else {
    if (value < 1) {
      return false;
    }
  }

  return true;
}

/* eslint-disable */
export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function baseSettings(data) {
  document.documentElement.style.setProperty(
    "--preloader-background",
    data.preloaderBackground
  );
  document.documentElement.style.setProperty(
    "--preloader-color",
    data.preloaderColor
  );
  document.documentElement.style.setProperty(
    "--preloader-line-background",
    data.preloaderLineBackground
  );
  document.documentElement.style.setProperty(
    "--preloader-line-one",
    data.preloaderLineOne
  );
  document.documentElement.style.setProperty(
    "--preloader-line-two",
    data.preloaderLineTwo
  );
}

export function apiSettings(data) {
  // backgraund_site
  document.documentElement.style.setProperty(
    "--backgraund-site-white",
    data.backgraund_site.background_white
  );
  document.documentElement.style.setProperty(
    "--backgraund-site-dark",
    data.backgraund_site.background_dark
  );
  document.documentElement.style.setProperty(
    "--backgraund-site-bg",
    data.backgraund_site.background_bg
  );
  document.documentElement.style.setProperty(
    "--backgraund-background-bg-sm",
    data.backgraund_site.background_bg_sm
  );
  document.documentElement.style.setProperty(
    "--backgraund-background-bg-sm-hover",
    data.backgraund_site.background_bg_sm_hover
  );
  document.documentElement.style.setProperty(
    "--backgraund-site-bg",
    data.backgraund_site.background_bg
  );
  document.documentElement.style.setProperty(
    "--backgraund-background-bg-rating-activ",
    data.backgraund_site.background_bg_rating_activ
  );
  document.documentElement.style.setProperty(
    "--backgraund-background-bg-rating-no-activ",
    data.backgraund_site.background_bg_rating_no_activ
  );

  document.documentElement.style.setProperty(
    "--backgraund-background-bg-ctrl",
    data.backgraund_site.background_bg_ctrl
  );
  document.documentElement.style.setProperty(
    "--backgraund-background-bg-ctrl-hover",
    data.backgraund_site.background_bg_ctrl_hover
  );

  // header
  document.documentElement.style.setProperty(
    "--main-menu-color-white",
    data.main_menu.main_menu_color_white
  );
  document.documentElement.style.setProperty(
    "--main-menu-color-white-hover",
    data.main_menu.main_menu_color_white_hover
  );
  document.documentElement.style.setProperty(
    "--main-menu-color-white-phone",
    data.main_menu.main_menu_color_white_phone
  );
  document.documentElement.style.setProperty(
    "--main-menu-color-white-phone-hover",
    data.main_menu.main_menu_color_white_phone_hover
  );
  document.documentElement.style.setProperty(
    "--main-menu-color-white-burger",
    data.main_menu.main_menu_color_white_burger
  );
  document.documentElement.style.setProperty(
    "--main-menu-color-white-bg-burger",
    data.main_menu.main_menu_color_white_bg_burger
  );

  // title_1
  document.documentElement.style.setProperty(
    "--title1-fonts-family",
    data.title.h1.fonts_family
  );
  document.documentElement.style.setProperty(
    "--title1-fonts-size",
    data.title.h1.fonts_size
  );
  document.documentElement.style.setProperty(
    "--title1-line-height",
    data.title.h1.line_height
  );
  document.documentElement.style.setProperty(
    "--title1-color-white",
    data.title.h1.color_white
  );
  document.documentElement.style.setProperty(
    "--title1-color-dark",
    data.title.h1.color_dark
  );

  // title_2
  document.documentElement.style.setProperty(
    "--title2-fonts-family",
    data.title.h2.fonts_family
  );
  document.documentElement.style.setProperty(
    "--title2-fonts-size",
    data.title.h2.fonts_size
  );
  document.documentElement.style.setProperty(
    "--title2-line-height",
    data.title.h2.line_height
  );
  document.documentElement.style.setProperty(
    "--title2-color-white",
    data.title.h2.color_white
  );
  document.documentElement.style.setProperty(
    "--title2-color-dark",
    data.title.h2.color_dark
  );

  // title_3
  document.documentElement.style.setProperty(
    "--title3-fonts-family",
    data.title.h3.fonts_family
  );
  document.documentElement.style.setProperty(
    "--title3-fonts-size",
    data.title.h3.fonts_size
  );
  document.documentElement.style.setProperty(
    "--title3-line-height",
    data.title.h3.line_height
  );
  document.documentElement.style.setProperty(
    "--title3-color-white",
    data.title.h3.color_white
  );
  document.documentElement.style.setProperty(
    "--title3-color-dark",
    data.title.h3.color_dark
  );

  // title_4
  document.documentElement.style.setProperty(
    "--title4-fonts-family",
    data.title.h4.fonts_family
  );
  document.documentElement.style.setProperty(
    "--title4-fonts-size",
    data.title.h4.fonts_size
  );
  document.documentElement.style.setProperty(
    "--title4-line-height",
    data.title.h4.line_height
  );
  document.documentElement.style.setProperty(
    "--title4-color-white",
    data.title.h4.color_white
  );
  document.documentElement.style.setProperty(
    "--title4-color-dark",
    data.title.h4.color_dark
  );

  // title_5
  document.documentElement.style.setProperty(
    "--title5-fonts-family",
    data.title.h5.fonts_family
  );
  document.documentElement.style.setProperty(
    "--title5-fonts-size",
    data.title.h5.fonts_size
  );
  document.documentElement.style.setProperty(
    "--title5-line_height",
    data.title.h5.line_height
  );
  document.documentElement.style.setProperty(
    "--title5-color-white",
    data.title.h5.color_white
  );
  document.documentElement.style.setProperty(
    "--title5-color-dark",
    data.title.h5.color_dark
  );

  // subtitle_1
  document.documentElement.style.setProperty(
    "--subtitle1-fonts-family",
    data.subtitle.subtitle_1.fonts_family
  );
  document.documentElement.style.setProperty(
    "--subtitle1-fonts-size",
    data.subtitle.subtitle_1.fonts_size
  );
  document.documentElement.style.setProperty(
    "--subtitle1-line-height-subtitle",
    data.subtitle.subtitle_1.line_height_subtitle
  );
  document.documentElement.style.setProperty(
    "--subtitle1-color-white",
    data.subtitle.subtitle_1.color_white
  );
  document.documentElement.style.setProperty(
    "--subtitle1-color-dark",
    data.subtitle.subtitle_1.color_dark
  );

  // subtitle_2
  document.documentElement.style.setProperty(
    "--subtitle2-fonts-family",
    data.subtitle.subtitle_2.fonts_family
  );
  document.documentElement.style.setProperty(
    "--subtitle2-fonts-size",
    data.subtitle.subtitle_2.fonts_size
  );
  document.documentElement.style.setProperty(
    "--subtitle2-line-height-subtitle",
    data.subtitle.subtitle_2.line_height_subtitle
  );
  document.documentElement.style.setProperty(
    "--subtitle2-color-white",
    data.subtitle.subtitle_2.color_white
  );
  document.documentElement.style.setProperty(
    "--subtitle2-color-dark",
    data.subtitle.subtitle_2.color_dark
  );

  // subtitle_3
  document.documentElement.style.setProperty(
    "--subtitle3-fonts-family",
    data.subtitle.subtitle_3.fonts_family
  );
  document.documentElement.style.setProperty(
    "--subtitle3-fonts-size",
    data.subtitle.subtitle_3.fonts_size
  );
  document.documentElement.style.setProperty(
    "--subtitle3-line-height-subtitle",
    data.subtitle.subtitle_3.line_height_subtitle
  );
  document.documentElement.style.setProperty(
    "--subtitle3-color-white",
    data.subtitle.subtitle_3.color_white
  );
  document.documentElement.style.setProperty(
    "--subtitle3-color-dark",
    data.subtitle.subtitle_3.color_dark
  );

  // subtitle_4
  document.documentElement.style.setProperty(
    "--subtitle4-fonts-family",
    data.subtitle.subtitle_4.fonts_family
  );
  document.documentElement.style.setProperty(
    "--subtitle4-fonts-size",
    data.subtitle.subtitle_4.fonts_size
  );
  document.documentElement.style.setProperty(
    "--subtitle4-line-height-subtitle",
    data.subtitle.subtitle_4.line_height_subtitle
  );
  document.documentElement.style.setProperty(
    "--subtitle4-color-white",
    data.subtitle.subtitle_4.color_white
  );
  document.documentElement.style.setProperty(
    "--subtitle4-color-dark",
    data.subtitle.subtitle_4.color_dark
  );

  // label
  document.documentElement.style.setProperty(
    "--label-color-white",
    data.label.color_white
  );
  document.documentElement.style.setProperty(
    "--label-color-dark",
    data.label.color_dark
  );

  // input
  document.documentElement.style.setProperty(
    "--input-border-radius",
    data.input.border_radius
  );
  document.documentElement.style.setProperty(
    "--input-background-white",
    data.input.background_white
  );
  document.documentElement.style.setProperty(
    "--input-background-white-focus",
    data.input.background_white_focus
  );
  document.documentElement.style.setProperty(
    "--input-color-white",
    data.input.color_white
  );
  document.documentElement.style.setProperty(
    "--input-color-border-white",
    data.input.color_border_white
  );
  document.documentElement.style.setProperty(
    "--input-color-border-white-focus",
    data.input.color_border_white_focus
  );
  document.documentElement.style.setProperty(
    "--input-background-dark",
    data.input.background_dark
  );
  document.documentElement.style.setProperty(
    "--input-background-dark-focus",
    data.input.background_dark_focus
  );
  document.documentElement.style.setProperty(
    "--input-color-dark",
    data.input.color_dark
  );
  document.documentElement.style.setProperty(
    "--input-color-border-dark",
    data.input.color_border_dark
  );
  document.documentElement.style.setProperty(
    "--input-color-border-dark-focus",
    data.input.color_border_dark_focus
  );

  // button
  document.documentElement.style.setProperty(
    "--buttom-border-radius",
    data.buttom.border_radius
  );
  document.documentElement.style.setProperty(
    "--buttom-background-white",
    data.buttom.background_white
  );
  document.documentElement.style.setProperty(
    "--buttom-background-white-hover",
    data.buttom.background_white_hover
  );
  document.documentElement.style.setProperty(
    "--buttom-color-white",
    data.buttom.color_white
  );
  document.documentElement.style.setProperty(
    "--buttom-color-border-white",
    data.buttom.color_border_white
  );
  document.documentElement.style.setProperty(
    "--buttom-background-dark",
    data.buttom.background_dark
  );
  document.documentElement.style.setProperty(
    "--buttom-background-dark-hover",
    data.buttom.background_dark_hover
  );
  document.documentElement.style.setProperty(
    "--buttom-color-dark",
    data.buttom.color_dark
  );
  document.documentElement.style.setProperty(
    "--buttom-color-border-dark",
    data.buttom.color_border_dark
  );

  // buttonPlay
  document.documentElement.style.setProperty(
    "--buttom-play-background-play",
    data.buttom.background_play
  );
  document.documentElement.style.setProperty(
    "--buttom-play-background-play-hover",
    data.buttom.background_play_hover
  );
  document.documentElement.style.setProperty(
    "--buttom-play-background-bg-play",
    data.buttom.background_bg_play
  );
  document.documentElement.style.setProperty(
    "--buttom-play-background-bg-play-hover",
    data.buttom.background_bg_play_hover
  );
  document.documentElement.style.setProperty(
    "--buttom-play-background-pulse",
    data.buttom.background_pulse
  );

  // link
  document.documentElement.style.setProperty(
    "--link-color-white",
    data.link.color_white
  );
  document.documentElement.style.setProperty(
    "--link-color-dark",
    data.link.color_dark
  );
}
