<template>
  <section id="price" class="price">
    <div class="price__container container">
      <div
        class="price__title"
        v-if="content.tarif_title"
        v-html="content.tarif_title"
      ></div>
      <div
        class="price__description"
        v-if="content.tarif_desc"
        data-aos="custom-appearance"
        data-aos-duration="800"
        data-aos-delay="200"
        v-html="content.tarif_desc"
      ></div>
      <div class="price__swiper swiper">
        <ul class="price__swiper-wrapper swiper-wrapper">
          <ItemPrice
            parent-class="price__slide"
            swiper="true"
            v-for="item in content.tarif_list"
            :key="item"
            :content="item"
            :popup="content.tarif_popup"
          />
        </ul>
        <PaginationSlide parent-class="price__pagination" />
      </div>
    </div>
  </section>
</template>

<script>
import ItemPrice from "@/components/block/ItemPriceApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import Swiper, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    ItemPrice,
    PaginationSlide,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.tarif,
    };
  },
  mounted() {
    let slides = document.querySelectorAll(".price__slide");
    let inSlide = slides.length > 3;

    new Swiper(".price__swiper", {
      slidesPerView: "auto",
      spaceBetween: 15,
      modules: [Pagination],
      speed: 500,
      loop: false,
      pagination: {
        el: ".price__pagination",
        type: "bullets",
      },
      breakpoints: {
        768: {
          enabled: inSlide,
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 30,
          enabled: inSlide,
          slidesPerView: 3,
        },
      },
    });
  },
};
</script>

<style>
.price {
  overflow: hidden;
  margin-bottom: 170px;
}

.price__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.price__description {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.price__pagination {
  width: 290px !important;
  margin: 35px auto 0 auto;
}

@media (max-width: 1599.98px) {
  .price {
    margin-bottom: 150px;
  }

  .price__title {
    font-size: 40px;
  }

  .price__description {
    max-width: 450px;
    font-size: 12px;

    margin-bottom: 60px;
  }
}

@media (max-width: 1199.98px) {
  .price {
    margin-bottom: 130px;
  }

  .price__title {
    font-size: 32px;
  }

  .price__description {
    max-width: 364px;
    margin-bottom: 55px;
  }
}

@media (max-width: 991.98px) {
  .price {
    margin-bottom: 110px;
  }

  .price__title {
    font-size: 28px;
  }

  .price__description {
    font-size: 14px;

    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .price {
    margin-bottom: 90px;
  }

  .price__title {
    font-size: 26px;
  }

  .price__description {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 38px;
  }

  .price__swiper {
    overflow: visible;
  }

  .price__slide {
    width: 246px;
  }
}
</style>
