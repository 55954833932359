<template>
  <button :class="classObject">
    <slot />
  </button>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        [this.parentClass]: this.parentClass,
        ["button-link"]: true,
      },
    };
  },
};
</script>

<style>
.button-link {
  position: relative;

  display: block;
  padding: 0;
  border: none;

  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: var(--link-color-white);

  background-color: transparent;

  appearance: none;
  cursor: pointer;
}

.dark .button-link {
  color: var(--link-color-dark);
}

.button-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 1px;

  background-color: var(--link-color-white);
  transition: width 0.3s ease-in;
}

.dark .button-link::after {
  color: var(--link-color-dark);
}

.button-link:hover::after {
  width: 0;
}

.button-link__min {
  font-size: inherit;
}

.button-link__min::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 1px;

  background-color: var(--link-color-white);
  transition: width 0.3s ease-in;
}

.dark .button-link__min::after {
  color: var(--link-color-dark);
}
</style>