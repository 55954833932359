<template>
  <span :class="classObject">
    <svg
      class="button-slide-next__icon"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path d="M8 16L15 8.5L8 1" stroke="#ffffff" stroke-linecap="round" />
      <rect y="8" width="15" height="1" rx="0.5" fill="#ffffff" />
    </svg>
  </span>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        ["button-slide-next"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button-slide-next {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  background-color: var(--backgraund-background-bg-ctrl);
  backdrop-filter: blur(10px);

  cursor: pointer;
  overflow: hidden;
  user-select: none;
  transition: background-color 0.2s ease;
}

.button-slide-next:hover {
  background-color: var(--backgraund-background-bg-ctrl);
  opacity: 0.8;
}

.button-slide-next:before {
  content: "";
  position: absolute;

  width: 60px;
  height: 60px;

  background-color: var(--backgraund-background-bg-ctrl-hover);

  transform: translateX(-60px);
  transition: transform 0.4s ease;
}

.button-slide-next:hover::before {
  transform: translateX(0);
}

.button-slide-next__icon {
  z-index: 2;

  display: block;
  width: 15px;
  height: 15px;
}

.button-slide-next_black path {
  stroke: #ffffff;
}

.button-slide-next_black rect {
  fill: #ffffff;
}

@media (max-width: 1599.98px) {
  .button-slide-next {
    width: 50px;
    height: 50px;
  }

  .button-slide-next:before {
    width: 50px;
    height: 50px;

    transform: translateX(-50px);
  }
}

@media (max-width: 1199.98px) {
  .button-slide-next {
    width: 40px;
    height: 40px;
  }

  .button-slide-next:before {
    width: 40px;
    height: 40px;

    transform: translateX(-40px);
  }
}

@media (max-width: 991.98px) {
  .button-slide-next {
    display: none;
  }
}
</style>