<template>
  <div :class="classObject">
    <div class="mobile-bar__container container">
      <div class="mobile-bar__wrapper">
        <ul class="mobile-bar__navigation">
          <li class="mobile-bar__item" v-for="item in menu" :key="item.id">
            <a
              class="mobile-bar__link"
              v-scroll-to="item.url"
              @click="$emit('itemMenuClick')"
              href="#"
              >{{ item.title }}</a
            >
          </li>
        </ul>
        <div class="mobile-bar__contacts">
          <a
            class="mobile-bar__phone"
            v-if="content.header_phone"
            :href="'tel:' + content.header_phone_link"
            v-on:click="phoneGoal()"
            >{{ content.header_phone }}</a
          >
          <a
            class="mobile-bar__address"
            :href="content.header_address_link"
            v-if="content.header_address"
            target="_blank"
            >{{ content.header_address }}</a
          >
          <a
            class="mobile-bar__mail"
            v-if="content.header_mail"
            :href="'mailto:' + content.header_mail"
            v-on:click="mailGoal()"
            >{{ content.header_mail }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        "mobile-bar": true,
        [this.parentClass]: this.parentClass,
      },
      content: this.$store.state.data.contentResponse.header,
      menu: this.$store.state.data.menuResponse,
    };
  },
  methods: {
    phoneGoal() {
      if (this.content.header_phone_metka) {
        this.$metrika.reachGoal(this.content.header_phone_metka);
      }
    },
    mailGoal() {
      if (this.content.header_mail_metka) {
        this.$metrika.reachGoal(this.content.header_mail_metka);
      }
    },
  },
};
</script>

<style>
.mobile-bar {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;

  width: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  transition: width 0s linear 2s;
}

.mobile .mobile-bar {
  width: 100vw;
  transition: width 0s linear 0s;
}

.mobile-bar__container {
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  max-height: 100%;
}

.mobile-bar::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;

  background-color: rgba(0, 0, 0, 0.6);

  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.7s linear, transform 0s linear 0.7s;
  will-change: transform;
}

.mobile .mobile-bar::after {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 0.7s linear, transform 0s linear 0s;
}

.mobile-bar::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 0;
  z-index: 0;

  background-color: #ffffff;

  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
}

.mobile .mobile-bar::before {
  transform: translateX(0);
  opacity: 1;
}

.mobile-bar__wrapper {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 100%;
  padding: 55px 0;

  text-align: right;

  transform: translateX(150%);
  transition: transform 0.4s ease-in-out;
}

.mobile .mobile-bar__wrapper {
  transform: translateX(0);
  transition-duration: 0.5s;
}

.mobile-bar__navigation {
  margin: auto 0;
}

.mobile-bar__item {
  margin-bottom: 22px;
}

.mobile-bar__item:last-child {
  margin-bottom: 0;
}

.mobile-bar__link {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  text-decoration: none;
  color: var(--main-menu-color-white);
}

.mobile-bar__link:hover {
  color: var(--main-menu-color-white-hover);
}

.mobile-bar__contacts {
  display: flex;
  flex-direction: column;
}

.mobile-bar__phone {
  margin-bottom: 15px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;

  color: var(--main-menu-color-white-phone);
}

.mobile-bar__address {
  margin-bottom: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  color: var(--main-menu-color-white-phone);
}

.mobile-bar__mail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: none;
  color: var(--main-menu-color-white-phone);
}
</style>
