<template>
  <section class="feedback dark">
    <div class="feedback__container container">
      <div class="feedback__title" v-if="content.form_title" v-html="content.form_title"></div>
      <p class="feedback__description" v-if="content.form_desc" v-html="content.form_desc"></p>
      <div class="feedback__button-wrap">
        <Button parent-class="feedback__button" v-on:click="openPopup()" v-if="content.form_button">{{
        content.form_button }}</Button>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    Button,
  },
  props: ["content"],
  methods: {
    openPopup() {
      if(this.content.form_url != "") {
        window.open(this.content.form_url, '_blank');
        return;
      }

      if (this.content.form_button_metka) {
        this.$metrika.reachGoal(this.content.form_button_metka);
      }
      this.$store.commit("updateQuestionPopup", {
        status: true,
        data: this.content.form_popup,
      });
    },
  },
};
</script>

<style>
.feedback {
  margin-bottom: 170px;

  background-color: var(--backgraund-site-dark);
}

.feedback__container {
  display: flex;
  align-items: center;
  padding-top: 104px;
  padding-bottom: 104px;
}

.feedback__title {
  flex-basis: 440px;
  flex-grow: 0;
  flex-shrink: 0;

  font-family: var(--title3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title3-fonts-size);
  line-height: var(--title3-line-height);
  color: var(--title3-color-dark);
}

.feedback__description {
  flex-basis: 590px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  color: var(--subtitle3-color-dark);
}

.feedback__button-wrap {
  flex-grow: 1;
  padding-right: 120px;
}

.feedback__button {
  margin-left: auto;
}

@media (max-width: 1599.98px) {
  .feedback {
    margin-bottom: 150px;
  }

  .feedback__container {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .feedback__title {
    flex-basis: 310px;

    font-size: 30px;
  }

  .feedback__description {
    flex-basis: 560px;
    padding: 0 60px;
  }

  .feedback__button-wrap {
    padding-right: 0;
  }
}

@media (max-width: 1199.98px) {
  .feedback {
    margin-bottom: 130px;
  }

  .feedback__container {
    padding-top: 52px;
    padding-bottom: 52px;
  }

  .feedback__title {
    flex-basis: 275px;

    font-size: 24px;
  }

  .feedback__description {
    flex-basis: 360px;
    padding: 0 55px;
  }

  .feedback__button-wrap {
    padding-right: 0;
  }
}

@media (max-width: 991.98px) {
  .feedback {
    margin-bottom: 110px;
  }

  .feedback__container {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;

    text-align: center;
  }

  .feedback__title {
    max-width: 426px;
    flex-basis: auto;
    margin-bottom: 10px;

    font-size: 26px;
  }

  .feedback__description {
    max-width: 426px;
    flex-basis: auto;
    padding: 0;
    margin-bottom: 20px;

    font-size: 14px;
  }

  .feedback__button-wrap {
    max-width: 426px;
  }
}

@media (max-width: 767.98px) {
  .feedback {
    margin-bottom: 90px;
  }

  .feedback__container {
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .feedback__title {
    max-width: 100%;
    flex-basis: auto;
    margin-bottom: 10px;

    font-size: 26px;
  }

  .feedback__description {
    max-width: 100%;
    flex-basis: auto;
  }

  .feedback__button-wrap {
    max-width: 100%;
  }
}
</style>
