<template>
  <Header />
  <main>
    <Offer v-if="!section.offer3.offer_1_visibility" />
    <Offer2 v-if="!section.offer3.offer_2_visibility" />
    <Offer3 v-if="!section.offer3.offer_3_visibility" />
    <Services v-if="!section.services.services_visibility" />
    <Advantages v-if="!section.advantages.advantages_visibility" />
    <Documents v-if="!section.documents.documents_visibility" />
    <Feedback :content="capturePoint" v-if="!section.form1.form_visibility" />
    <Doctors v-if="!section.doctors.doctors_visibility" />
    <Price v-if="!section.tarif.tarif_visibility" />
    <Stocks v-if="!section.stocks.stocks_visibility" />
    <PartnersFull v-if="!section.partners.partners_visibility" />
    <AdditionalOffer v-if="!section.additional_offers.visibility" />
    <Company v-if="!section.company.company_visibility" />
    <Gallery v-if="!section.gallery.gallery_visibility" />
    <Partners v-if="!section.partners.partners_visibility" />
    <Feedback :content="capturePoint2" v-if="!section.form2.form_visibility" />
    <Reviews v-if="!section.reviews.reviews_visibility" />
    <ProgressWork v-if="!section.progress_work.progress_work_visibility" />
    <Contacts />
  </main>
  <Footer />
  <LinkBar />
  <TopButtom />
  <ServicesPopup v-if="$store.state.servicesPopup.status" />
  <OrderPopup v-if="$store.state.orderPopup.status" />
  <QuestionPopup v-if="$store.state.questionPopup.status" />
  <DoctorsPopup v-if="$store.state.doctorsPopup.status" />
  <StatusFormPopop v-if="$store.state.statusFormPopup" />
</template>

<script>
import Header from "@/components/section/HeaderApp.vue";
import Offer from "@/components/section/OfferApp.vue";
import Offer2 from "@/components/section/Offer2App.vue";
import Offer3 from "@/components/section/Offer3App.vue";
import Services from "@/components/section/ServicesApp.vue";
import Advantages from "@/components/section/AdvantagesApp.vue";
import Feedback from "@/components/section/FeedbackApp.vue";
import Gallery from "@/components/section/GalleryApp.vue";
import Doctors from "@/components/section/DoctorsApp.vue";
import Price from "@/components/section/PriceApp.vue";
import Stocks from "@/components/section/StocksApp.vue";
import PartnersFull from "@/components/section/PartnersFull.vue";
import AdditionalOffer from "@/components/section/AdditionalOfferApp.vue";
import Company from "@/components/section/CompanyApp.vue";
import Partners from "@/components/section/PartnersApp.vue";
import Reviews from "@/components/section/ReviewsApp.vue";
import Documents from "@/components/section/DocumentsApp.vue";
import ProgressWork from "@/components/section/ProgressWorkApp.vue";
import Contacts from "@/components/section/ContactsApp.vue";
import Footer from "@/components/section/FooterApp.vue";
import ServicesPopup from "@/components/popup/ServicesPopup.vue";
import OrderPopup from "@/components/popup/OrderPopup.vue";
import QuestionPopup from "@/components/popup/QuestionPopup.vue";
import DoctorsPopup from "@/components/popup/DoctorsPopup.vue";
import LinkBar from "@/components/block/LinkBarApp.vue";
import StatusFormPopop from "@/components/popup/StatusFormPopop.vue";
import TopButtom from "@/components/element/TopButtomApp.vue";
import { apiSettings } from "@/helpers/index.js";

export default {
  components: {
    Header,
    Offer,
    Offer2,
    Offer3,
    Services,
    Advantages,
    Feedback,
    Gallery,
    Price,
    Stocks,
    AdditionalOffer,
    Company,
    Partners,
    Reviews,
    Documents,
    ProgressWork,
    Contacts,
    Footer,
    ServicesPopup,
    OrderPopup,
    QuestionPopup,
    Doctors,
    LinkBar,
    DoctorsPopup,
    StatusFormPopop,
    TopButtom,
    PartnersFull
  },
  data() {
    return {
      capturePoint: this.$store.state.data.contentResponse.form1,
      capturePoint2: this.$store.state.data.contentResponse.form2,
      section: this.$store.state.data.contentResponse,
    };
  },
  mounted() {
    apiSettings(this.$store.state.data.settingsResponse);
  },
};
</script>

<style></style>
