import { createApp } from "vue";
import App from "./App.vue";
import VueYandexMetrika from "vue3-yandex-metrika";
import Store from "@/store/index.js";
import VueScrollTo from "vue-scrollto";
import mediaWp from "@/plugins/mediaWp.js";
import scrollMenu from "@/directives/scrollMenu.js";
import inputMask from "@/directives/inputMask.js";
import VueLazyLoad from 'vue3-lazyload'
import { baseSettings } from "./helpers";
import "@/assets/css/st-global-v1.css";
import "@/assets/css/st-fonts-v1.css";
import "aos/dist/aos.css";

baseSettings(window.settingsApp); // Устанавливаем css переменные базовых настроек

if (window.settingsApp.fonts.length) {
  if (window.settingsApp.fonts.length === 1) {
    document.head.insertAdjacentHTML(
      "beforeend",
      `<link href="https://fonts.googleapis.com/css2?family=${window.settingsApp.fonts[0]}:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap" rel="stylesheet">`
    );
  } else {
    console.log(document.head);
    document.head.insertAdjacentHTML(
      "beforeend",
      `<link href="https://fonts.googleapis.com/css2?family=${window.settingsApp.fonts[0]}:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=${window.settingsApp.fonts[1]}:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap" rel="stylesheet">`
    );
  }
}

let app = createApp(App)
  .use(Store)
  .use(VueScrollTo)
  .use(mediaWp)
  .use(VueLazyLoad)
  .directive("scroll-menu", scrollMenu)
  .directive("input-mask", inputMask);

if (!navigator.userAgent.toLowerCase().includes('lighthouse')) {
  if (window.settingsApp.metrika) {
    app.use(VueYandexMetrika, {
      id: window.settingsApp.metrika,
      env: "production",
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    });
  }
}

app.mount("#app");
