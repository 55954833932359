<template>
  <section id="reviews" class="reviews">
    <div class="reviews__container container">
      <div
        class="reviews__title"
        v-if="content.reviews_title"
        v-html="content.reviews_title"
      ></div>
      <div
        class="reviews__description"
        v-if="content.reviews_desc"
        v-html="content.reviews_desc"
      ></div>
      <div class="reviews__swiper-wrapper" v-if="content.reviews_list">
        <div class="reviews__swiper swiper">
          <ul class="reviews__list swiper-wrapper">
            <ItemReviews
              swiper="true"
              v-for="item in content.reviews_list"
              :key="item"
              :content="item"
            />
          </ul>
        </div>
        <div class="reviews__slide-button">
          <ButtonSlidePrev parent-class="reviews__prev" />
          <ButtonSlideNext parent-class="reviews__next" />
          <PaginationSlide parent-class="reviews__pagination" />
        </div>
      </div>
      <a class="reviews__link" :href="content.link.url" target="_blank" v-if="content.link.url">{{ content.link.ancor }}</a>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
import ItemReviews from "@/components/block/ItemReviewsApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    ItemReviews,
    PaginationSlide,
    ButtonSlidePrev,
    ButtonSlideNext,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.reviews,
    };
  },
  mounted() {
    new Swiper(".reviews__swiper", {
      modules: [Navigation, Pagination],
      centerInsufficientSlides: true,
      loop: true,
      speed: 500,
      navigation: {
        nextEl: ".reviews__next",
        prevEl: ".reviews__prev",
      },
      pagination: {
        el: ".reviews__pagination",
        type: "bullets",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  },
};
</script>

<style>
.reviews {
  margin-bottom: 170px;
}

.reviews__container {
  display: flex;
  flex-direction: column;
}

.reviews__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.reviews__description {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.reviews__swiper-wrapper {
  position: relative;
}

.reviews__next,
.reviews__prev {
  position: absolute;
  top: 130px;
  z-index: 1;
}

.reviews__next {
  right: -30px;
}

.reviews__prev {
  left: -30px;
}

.reviews__pagination {
  display: none;
}

.reviews__link {
  margin-top: 50px;
  align-self: center;

  font-family: var(--subtitle3-fonts-family);
  font-size: 14px;
  color: var(--link-color-white);
}

.reviews__link:hover {
  text-decoration: none;
}

@media (max-width: 1599.98px) {
  .reviews {
    margin-bottom: 150px;
  }

  .reviews__title {
    font-size: 40px;
    line-height: 120%;
  }

  .reviews__description {
    max-width: 450px;
    margin-bottom: 60px;

    font-size: 12px;
  }

  .reviews__next,
  .reviews__prev {
    top: 120px;
  }

  .reviews__next {
    right: -25px;
  }

  .reviews__prev {
    left: -25px;
  }
}

@media (max-width: 1199.98px) {
  .reviews {
    margin-bottom: 130px;
  }

  .reviews__title {
    font-size: 32px;
  }

  .reviews__description {
    max-width: 364px;
    margin-bottom: 55px;
  }

  .reviews__next,
  .reviews__prev {
    top: 120px;
  }

  .reviews__next {
    right: -20px;
  }

  .reviews__prev {
    left: -20px;
  }
}

@media (max-width: 991.98px) {
  .reviews {
    margin-bottom: 110px;
  }

  .reviews__title {
    margin-bottom: 10px;

    font-size: 28px;
  }

  .reviews__description {
    margin-bottom: 50px;

    font-size: 14px;
  }

  .reviews__next,
  .reviews__prev {
    top: calc(50% - 20px);
  }

  .reviews__pagination {
    display: flex;
    width: 290px !important;
    margin: 35px auto 0 auto;
  }

  .reviews__link {
    margin-top: 30px;
  }
}

@media (max-width: 767.98px) {
  .reviews {
    margin-bottom: 90px;
  }

  .reviews__title {
    font-size: 26px;
  }

  .reviews__description {
    max-width: calc(100% - 30px);
    max-width: 364px;
    margin-bottom: 45px;
  }
}
</style>
