<template>
  <section class="offer-2" :class="{ dark: !content.offer_3_style }">
    <div class="offer-2__swiper swiper">
      <div class="offer-2__wrapper swiper-wrapper">
        <div
          class="offer-2__slide swiper-slide"
          :style="{
            backgroundImage:
              'url(' + item.offer_3_list_img.sizes.offer2 + ')',
          }"
          v-for="item in content.offer_3_list"
          :key="item"
        >
          <div class="container">
            <div class="offer-2__content">
              <div
                class="offer-2__title"
                v-html="item.offer_3_list_title"
                if="item.offer_3_list_title"
              ></div>
              <div
                class="offer-2__desc"
                v-html="item.offer_3_list_desc"
                if="item.offer_3_list_desc"
              ></div>
              <Button
                parent-class="offer-2__button"
                v-on:click="openPopup(item)"
                if="item.offer_3_list_button"
                >{{ item.offer_3_list_button }}</Button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="offer-2__slide-button">
        <ButtonSlidePrev parent-class="button-slide-prev_black offer-2__prev" />
        <ButtonSlideNext parent-class="button-slide-next_black offer-2__next" />
      </div>
    </div>
    <PaginationSlide parent-class="offer-2__pagination" />
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    Button,
    PaginationSlide,
    ButtonSlidePrev,
    ButtonSlideNext,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer3,
    };
  },
  methods: {
    openPopup(item) {
      if(item.offer_3_url != "") {
        window.open(item.offer_3_url, '_blank');
        return;
      }

      if (item.offer_3_list_button_metka) {
        this.$metrika.reachGoal(item.offer_3_list_button_metka);
      }

      this.$store.commit("updateOrderPopup", {
        status: true,
        data: item.offer_3_list_popup,
        name: item.offer_3_list_title,
      });
    },
  },
  mounted() {
    new Swiper(".offer-2__swiper", {
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1000,
      rewind: true,
      grabCursor: true,
      effect: "fade",
      autoplay: {
        enabled: true,
        delay: 7000,
        reverseDirection: true,
      },
      navigation: {
        nextEl: ".offer-2__next",
        prevEl: ".offer-2__prev",
      },
      pagination: {
        el: ".offer-2__pagination",
        type: "bullets",
      },
    });
  },
};
</script>

<style>
.offer-2 {
  position: relative;
  z-index: 0;

  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 730px;
  margin: 0 auto;
  margin-bottom: 170px;

  overflow: hidden;
}

.offer-2__swiper {
  height: 100%;
}

.offer-2__wrapper {
  height: 100%;
}

.offer-2__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 105px;
  padding-bottom: 80px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.offer-2__title {
  max-width: 60%;
  margin-bottom: 40px;

  font-family: var(--title1-fonts-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--title1-fonts-size);
  line-height: var(--title1-line-height);
  color: var(--title1-color-white);
}

.dark .offer-2__title {
  color: var(--title1-color-dark);
}

.offer-2__desc {
  width: 569px;
  margin-bottom: 40px;

  font-family: var(--subtitle1-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle1-fonts-size);
  line-height: var(--subtitle1-line-height);
  color: var(--subtitle1-color-white);
}

.dark .offer-2__desc {
  color: var(--title2-color-dark);
}

.offer-2__slide-button {
  position: absolute;
  left: calc(50% - 705px);
  bottom: 65px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 140px;
}

.offer-2__pagination {
  display: none;
}

@media (max-width: 1599.98px) {
  .offer-2 {
    min-height: 650px;
    margin-bottom: 150px;
  }

  .offer-2__slide {
    padding-top: 71px;
    padding-bottom: 80px;
  }

  .offer-2__title {
    width: 600px;
    margin-bottom: 30px;

    font-size: 48px;
  }

  .offer-2__desc {
    width: 520px;
    margin-bottom: 30px;

    font-size: 16px;
    line-height: 160%;
  }

  .offer-2__slide-button {
    left: calc(50% - 535px);
    width: 120px;
  }

  .offer-2__pagination {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .offer-2 {
    margin-bottom: 130px;
  }

  .offer-2__slide {
    padding-bottom: 80px;
  }

  .offer-2__title {
    width: 500px;
    margin-bottom: 20px;

    font-size: 36px;
  }

  .offer-2__desc {
    width: 480px;
    margin-bottom: 20px;
  }

  .offer-2__slide-button {
    left: calc(50% - 480px);
    width: 100px;
  }
}

@media (max-width: 991.98px) {
  .offer-2 {
    margin-bottom: 130px;
  }

  .offer-2__slide {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .offer-2__swiper {
    height: calc(100vh - 40px);
  }

  .offer-2__title {
    width: 450px;
    margin-bottom: 20px;

    font-size: 32px;
  }

  .offer-2__desc {
    width: 400px;

    font-size: 14px;
  }

  .offer-2__slide-button {
    display: none;
  }

  .offer-2__pagination {
    display: flex;
    width: 290px !important;
    margin: 20px auto 0 auto;
  }
}

@media (max-width: 767.98px) {
  .offer-2 {
    margin-bottom: 130px;
  }

  .offer-2__slide {
    padding-bottom: 90px;
  }

  .offer-2__content {
    widows: 100%;
    max-width: 450px;
  }

  .offer-2__title {
    max-width: 95%;
    width: 100%;
    margin-bottom: 15px;

    font-size: 28px;
  }

  .offer-2__desc {
    width: 100%;
    margin-bottom: 20px;
  }

  .offer-2__slide-button {
    display: none;
  }
}
</style>
