<template>
  <section id="сontacts" class="сontacts">
    <Map :content="content.contacts_map" />
    <div class="сontacts__info">
      <div class="сontacts__title" v-if="content.contacts_title" v-html="content.contacts_title"></div>
      <p class="сontacts__phone" v-if="content.contacts_phone.link || content.contacts_phone.text">
        <a class="сontacts__phone-link" :href="'tel:' + content.contacts_phone.link" v-on:click="phoneGoal()">{{
        content.contacts_phone.text }}</a>
      </p>
      <p class="сontacts__address" v-if="content.contacts_address.link || content.contacts_address.text">
        <a class="сontacts__address-link" :href="content.contacts_address.link" target="_blank">{{
        content.contacts_address.text }}</a>
      </p>
      <p class="сontacts__mail" v-if="content.contacts_mail">
        <a class="сontacts__mail-link" :href="'mailto:' + content.contacts_mail" v-if="content.contacts_mail">{{ content.contacts_mail }}</a>
      </p>
      <ul class="сontacts__work" v-if="content.contacts_work_schedule">
        <li
          class="сontacts__work-item"
          v-for="item in content.contacts_work_schedule"
          :key="item"
        >
          <span class="сontacts__day">{{ item.footer_work_schedule_days }}</span
          >{{ item.footer_work_schedule_hours }}
        </li>
      </ul>
      <form class="сontacts__form" action="#">
        <p :class="errors.name ? 'сontacts__field error' : 'сontacts__field'">
        <Label parent-class="сontacts__label">{{
        errors.name ? "* Поле “Имя” не указано" : "* Имя"
        }}</Label>
        <span class="input_wrapper">
          <InputText parent-class="сontacts__input" name="сontacts-name"
            v-model="dataForm.your_name" type="text" />
        </span>
      </p>
        <p :class="errors.phone ? 'сontacts__field error' : 'сontacts__field'">
          <Label parent-class="сontacts__label">{{
          errors.phone ? "* Поле “Телефон” не указано" : "* Телефон"
          }}</Label>
          <span class="input_wrapper">
            <InputText parent-class="сontacts__input" v-input-mask v-model="dataForm.your_phone" type="tel"
              placeholder="+ 7 (" />
          </span>
        </p>
        <p class="сontacts__politics">
          <input type="checkbox" name="сontacts__politics" id="сontacts__politics" v-model="isPolitics">
          <label :class="errors.politics ? 'сontacts__politics-label error' : 'сontacts__politics-label'" v-html="content.contacts_politics" for="сontacts__politics"></label>
        </p>
        <Button parent-class="сontacts__button" v-on:click.prevent="submitForm">{{ content.contacts_button }}</Button>
      </form>
    </div>
  </section>
</template>

<script>
import Map from "@/components/block/MapApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { validation } from "@/helpers/index.js";

export default {
  components: {
    Map,
    Button,
    InputText,
    Label,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.contacts,
      content1: this.$store.state.data.contentResponse.header,
      isPolitics: true,
      dataForm: {
        id: 1033,
        your_name: "",
        your_phone: "",
      },
      errors: {
        name: false,
        phone: false,
        politics: false
      },
    };
  },
  methods: {
    submitForm() {
      this.errors = { name: false, email: false, phone: false, politics: false };

      if (this.dataForm.your_name.length < 1) {
        this.errors.name = true;
        return;
      }

      if (!validation(this.dataForm.your_phone.length, 18)) {
        this.errors.phone = true;
        return;
      }

      if (!this.isPolitics) {
        this.errors.politics = true;
        return;
      }

      this.$store.dispatch("submitForm", {
        data: this.dataForm,
        mertica: {
          reach: this.$metrika.reachGoal,
          metka: this.content.contacts_button_metka
        }
      });

      this.dataForm = {
        id: 1033,
        your_name: "",
        your_phone: "",
      };
    },
    phoneGoal() {
      if (this.content1.header_phone_metka) {
        this.$metrika.reachGoal(this.content1.header_phone_metka);
      }
    },
  },
};
</script>

<style>
.сontacts {
  position: relative;
  z-index: 0;

  display: flex;
  justify-content: center;
  min-height: 689px;
  padding-top: 70px;
  padding-bottom: 70px;

  background-color: var(--backgraund-site-bg);

  overflow: hidden;
}

.сontacts__info {
  width: 690px;
  padding: 70px 120px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: calc((-1410px / 2 + 345px) * 2);

  background-color: #ffffff;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 20%);
}

.сontacts__title {
  margin-bottom: 50px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  color: var(--title2-color-white);
}

.dark .сontacts__title {
  color: var(--title2-color-dark);
}

.сontacts__phone {
  margin-bottom: 5px;
}

.сontacts__phone-link {
  position: relative;

  font-family: var(--subtitle1-fonts-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--subtitle1-fonts-size);
  line-height: 150%;
  text-decoration: none;
  color: var(--subtitle1-color-white);
}

.сontacts__phone-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: var(--subtitle1-color-white);
  transition: width 0.5s ease-in;
}

.сontacts__phone-link:hover::after {
  width: 100%;
}

.сontacts__address {
  margin-bottom: 5px;
}

.сontacts__address-link {
  position: relative;

  font-family: var(--subtitle2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle2-fonts-size);
  line-height: 150%;
  text-decoration: none;
  color: var(--subtitle2-color-white);
}

.сontacts__address-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: var(--subtitle2-color-white);
  transition: width 0.5s ease-in;
}

.сontacts__address-link:hover::after {
  width: 100%;
}

.сontacts__mail {
  margin-bottom: 20px;
}

.сontacts__mail-link {
  position: relative;

  font-family: var(--subtitle2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle2-fonts-size);
  line-height: 150%;
  text-decoration: none;
  color: var(--subtitle2-color-white);
}

.сontacts__mail-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: var(--subtitle2-color-white);
  transition: width 0.5s ease-in;
}

.сontacts__mail-link:hover::after {
  width: 100%;
}

.сontacts__work {
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-bottom: 30px;
}

.сontacts__work-item {
  margin-bottom: 3px;

  font-family: var(--subtitle2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle2-fonts-size);
  line-height: var(--subtitle4-line-height-subtitle);
  color: var(--subtitle2-color-white);
}

.dark .сontacts__work-item {
  color: var(--subtitle2-color-dark);
}

.сontacts__work-item:last-child {
  margin-bottom: 0;
}

.сontacts__day {
  margin-right: 20px;
}


.сontacts__field {
  width: 330px;
  margin-bottom: 10px;
}

.сontacts__label {
  display: block;
  margin-left: 15px;
  margin-bottom: 10px;
}

.сontacts__button {
  width: 330px;
  margin-bottom: 25px;
}

.contacts_politics {
  font-family: var(--subtitle4-fonts-family);
  font-size: var(--subtitle4-fonts-size);
  color: var(--subtitle4-color-white);
}

.сontacts__politics {
  line-height: 1.5;
  margin-bottom: 15px;
}

.сontacts__politics-label {
  margin-left: 5px;
}

.сontacts__politics-label.error {
  color:#d92641;
}

.сontacts__politics input {
  margin-top: -1px;
  vertical-align: middle;
}

@media screen and (max-width: 1599.98px) {
  .сontacts {
    min-height: 540px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .сontacts__info {
    width: 522px;
    padding: 50px 93px;
    margin-right: calc((-1074px / 2 + 261px) * 2);
  }

  .сontacts__title {
    margin-bottom: 30px;

    font-size: 40px;
    line-height: 120%;
  }

  .сontacts__phone {
    margin-bottom: 5px;
  }

  .сontacts__phone-link {
    font-size: 16px;
  }

  .сontacts__address {
    margin-bottom: 41px;
  }

  .сontacts__address-link {
    font-size: 14px;
  }

  .сontacts__field {
    width: 276px;
  }

  .contacts__politics-checkbox {
    margin-right: 10px;
  }

  .сontacts__button {
    width: 276px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199.98px) {
  .сontacts {
    min-height: 508px;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .сontacts__info {
    width: 468px;
    padding: 55px 84px;
    margin-right: calc((-966px / 2 + 234px) * 2);
  }

  .сontacts__title {
    margin-bottom: 32px;

    font-size: 32px;
  }

  .сontacts__phone {
    margin-bottom: 15px;
  }

  .сontacts__phone-link {
    font-size: 18px;
  }

  .сontacts__address {
    margin-bottom: 30px;
  }

  .сontacts__address-link {
    font-size: 14px;
  }

  .сontacts__field {
    width: 249px;
  }

  .сontacts__button {
    width: 249px;
  }
}

@media screen and (max-width: 991.98px) {
  .сontacts {
    min-height: 472px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .сontacts__info {
    width: 392px;
    padding: 50px 41px;
    margin-right: calc((-592px / 2 + 196px) * 2);
  }

  .сontacts__title {
    margin-bottom: 30px;

    font-size: 28px;
  }

  .сontacts__phone {
    margin-bottom: 10px;
  }

  .сontacts__address {
    margin-bottom: 5px;
  }

  .сontacts__field {
    width: 240px;
  }

  .сontacts__button {
    width: 240px;
  }
}

@media screen and (max-width: 767.98px) {
  .сontacts {
    min-height: 614px;
    padding-top: 35px;
    padding-bottom: 200px;
  }

  .сontacts__info {
    width: 290px;
    padding: 45px 22px;
    margin-right: calc((-536px / 2 + 145px) * 2);
  }

  .сontacts__title {
    font-size: 26px;
  }
}

@media screen and (max-width: 575.98px) {
  .сontacts__info {
    margin-right: calc((-493px / 2 + 145px) * 2);
  }
}

@media screen and (max-width: 532.98px) {
  .сontacts {
    min-height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .сontacts__info {
    margin-right: 0;
  }
}
</style>
