<template>
  <li :class="[classObject, 'card-gallery_vertical', 'card-gallery__doctors']" v-if="type">
      <div class="card-gallery__link" v-on:click="openPopup()">
      <img class="card-gallery__img" :src="content.doctors_id_img.url" alt="" />
      <p class="card-gallery__name">{{ content.doctors_name }}</p>
      <p class="card-gallery__desc">{{ content.doctors_desc }}</p>
    </div>
  </li>
  <li :class="[classObject, imageOrientation(content.id)]" v-else>
    <a class="card-gallery__link" :href="content.url" data-fancybox="card-gallery">
      <div class="card-gallery__icon"></div>
      <img class="card-gallery__img" :src="content.url" alt="" />
    </a>
  </li>
</template>

<script>
export default {
  props: ["parentClass", "swiper", "content", "type"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["card-gallery"]: true,
        [this.parentClass]: this.parentClass,
      },
      media: this.$store.state.data.mediaResponse,
    };
  },
  methods: {
    imageOrientation(id) {
      if (!this.media[id]) {
        // Проверяем присутствует ли данный файл в Store
        console.log("Не найдено подходящего файла");
        return;
      }

      let width = this.media[id].media_details.width;
      let height = this.media[id].media_details.height;

      return width >= height ? "card-gallery_horizon" : "card-gallery_vertical";
    },
    openPopup() {
      if (this.content.doctors_button_metka) {
        this.$metrika.reachGoal(this.content.doctors_button_metka);
      }

      this.$store.commit("updateDoctorsPopup", {
        status: true,
        data: this.content,
      });
    },
  },
};
</script>
<style>
.card-gallery {
  position: relative;

  width: 330px !important;
}

.card-gallery__doctors {
  cursor: pointer;
}

.card-gallery_horizon {
  height: 330px !important;
}

.card-gallery_vertical {
  height: 442px !important;
}

.card-gallery__doctors.card-gallery_vertical {
  height: auto !important;
}

.card-gallery__icon {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
  border-radius: 50%;

  background-color: var(--backgraund-background-bg-rating-activ);
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.card-gallery__icon::before {
  content: "";

  width: 16px;
  height: 16px;

  background-image: url(@/assets/img/magnifier.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.card-gallery:hover .card-gallery__icon {
  opacity: 0.6;
}

.card-gallery__img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.card-gallery__doctors .card-gallery__img {
  margin-bottom: 10px;
}

.card-gallery__name {
  margin-bottom: 10px;
  font-family: var(--title4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title4-fonts-size);
  line-height: var(--title4-line-height);
  color: var(--title4-color-white);
}

.card-gallery__desc {
  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  color: var(--subtitle3-color-white);
}

@media (max-width: 1599.98px) {
  .card-gallery {
    width: 246px !important;
  }

  .card-gallery_horizon {
    height: 246px !important;
  }

  .card-gallery_vertical {
    height: 315px !important;
  }

  .card-gallery__doctors .card-gallery_vertical {
  height: auto !important;
}

  .card-documents__icon {
    top: calc(50% - 30px);
    left: calc(50% - 30px);

    width: 60px;
    height: 60px;
  }

  .card-documents__icon::before {
    width: 14px;
    height: 14px;
  }

  .card-gallery__name {
    font-size: 16px;
  }

  .card-gallery__desc {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .card-gallery {
    width: 219px !important;
  }

  .card-gallery_horizon {
    height: 219px !important;
  }

  .card-gallery_vertical {
    height: 280px !important;
  }

  .card-gallery__doctors .card-gallery_vertical {
  height: auto !important;
}
}

@media (max-width: 991.98px) {
  .card-gallery {
    width: 184px !important;
  }

  .card-gallery_horizon {
    height: 184px !important;
  }

  .card-gallery_vertical {
    height: 236px !important;
  }

  .card-gallery__doctors .card-gallery_vertical {
  height: auto !important;
}
}
</style>
