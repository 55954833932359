<template>
  <li :class="classObject" data-aos="custom-appearance" data-aos-duration="1000" :data-aos-delay="300 * number">
    <div class="card-progress-work__icon">{{ number + 1 }}</div>
    <div class="card-progress-work__info">
      <div class="card-progress-work__name" v-html="content.progress_work_list_name"></div>
      <p class="card-progress-work__description">
        {{ content.progress_work_list_desc }}
      </p>
    </div>
  </li>
</template>

<script>
export default {
  props: ["parentClass", "content", "number"],
  data() {
    return {
      classObject: {
        [this.parentClass]: this.parentClass,
        ["card-progress-work"]: true,
      },
    };
  },
};
</script>

<style>
.card-progress-work {
  position: relative;
  z-index: 50;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;

  text-align: center;
}

.card-progress-work__icon {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 20px;
  margin-bottom: 35px;

  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5;
  color: #ffffff;

  background-color: var(--backgraund-site-dark);
}

.card-progress-work:nth-child(4) .card-progress-work__icon:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  z-index: -1;
  opacity: 0;

  border-radius: 25px;

  background-color: #c4c4c4;
  animation: pulse-border 1500ms ease-out 3s infinite;
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.card-progress-work__name {
  margin-bottom: 20px;

  font-family: var(--title4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title4-fonts-size);
  line-height: 1.4;
  color: var(--title4-color-white);
}

.card-progress-work__description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

@media (max-width: 1599.98px) {
  .card-progress-work__icon {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-bottom: 35px;

    font-size: 20px;
  }

  .card-progress-work__icon::before {
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;

    border-radius: 15px;
  }

  .card-progress-work__name {
    margin-bottom: 15px;

    font-size: 16px;
  }

  .card-progress-work__description {
    font-size: 12px;
  }
}

@media (max-width: 1199.98px) {
  .card-progress-work__icon {
    width: 54px;
    height: 54px;
    border-radius: 10px;
    margin-bottom: 22px;

    font-size: 18px;
  }

  .card-progress-work__icon::before {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;

    border-radius: 12px;
  }

  .card-progress-work__name {
    margin-bottom: 10px;

    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .card-progress-work {
    flex-direction: row;
    align-items: flex-start;
    height: 130px;
    padding-top: 0;

    text-align: left;
  }

  .card-progress-work__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50px;
    height: 50px;

    margin-right: 25px;
    margin-bottom: 0;

    font-size: 18px;
  }

  .card-progress-work__icon::before {
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;

    border-radius: 15px;
  }

  .card-progress-work__name {
    margin-bottom: 15px;
  }

  .card-progress-work__description {
    font-size: 12px;
  }
}
</style>
