<template>
  <section id="partners" class="partners">
    <div class="container">
    <div
        class="partners__title"
        v-if="content.partners_title"
        v-html="content.partners_title"
      ></div>
      <div
        class="partners__description"
        v-if="content.partners_desc"
        data-aos="custom-appearance"
        data-aos-duration="800"
        data-aos-delay="200"
        v-html="content.partners_desc"
      ></div>
    </div>
      <div class="partners__swiper swiper">
        <ul class="partners__list swiper-wrapper">
          <ItemPartners swiper="true" v-for="item in content.partners_list" :key="item" :content="item" />
        </ul>
      </div>
  </section>
</template>

<script>
import ItemPartners from "@/components/block/ItemPartnersApp.vue";
import Swiper, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

export default {
  components: {
    ItemPartners,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.partners,
    };
  },
  mounted() {
    new Swiper(".partners__swiper", {
      modules: [Autoplay],
      slidesPerView: 1.5,
      loop: true,
      lazy: true,
      speed: 6000,
      loopFillGroupWithBlank: true,
      autoplay: {
        enabled: true,
        delay: 1,
        reverseDirection: true,
      },
      breakpoints: {
        425: {
          slidesPerView: 2.5,
          spaceBetween: 60,
        },
        767: {
          slidesPerView: 4,
          spaceBetween: 70,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 83,
        },
        2000: {
          slidesPerView: 7,
          spaceBetween: 150,
        },
      },
    });
  },
};
</script>

<style>
.partners {
  margin-bottom: 150px;
}

.partners__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.partners__description {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.partners__list {
  align-items: center;

  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

@media (max-width: 1599.98px) {
  .partners {
    margin-bottom: 150px;
  }

  .partners__title {
    font-size: 40px;
  }

  .partners__description {
    max-width: 450px;
    font-size: 12px;

    margin-bottom: 60px;
  }
}

@media (max-width: 1199.98px) {
  .partners {
    margin-bottom: 130px;
  }

  .partners__title {
    font-size: 32px;
  }

  .partners__description {
    max-width: 364px;
    margin-bottom: 55px;
  }
}

@media (max-width: 991.98px) {
  .partners {
    margin-bottom: 110px;
  }

  .partners__title {
    font-size: 28px;
  }

  .partners __description {
    font-size: 14px;

    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .partners {
    margin-bottom: 90px;
  }

  .partners__title {
    font-size: 26px;
  }

  .partners__description {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 38px;
  }
}
</style>