export default {
  created: function (el) {
    let prevScroll = 0; // Начальное значение скролла

    window.addEventListener('scroll', () => {
     let currentScroll = document.documentElement.scrollTop; // Получаем текущее значение скролла

     if (el.classList.contains('mobile')) { // Проверяем открытие мобильного меню
       return;
     }

      if (currentScroll > prevScroll && currentScroll > 50) { // Направление скролла вниз
        el.classList.add("show");
        el.classList.add("bg");

      } else { // Скролинг вниз
        el.classList.remove("show");
       

        if (currentScroll < 50) {
          el.classList.remove("show");
          el.classList.remove("bg");
        }
      }

      prevScroll = currentScroll;
    })
  }
}