<template>
  <nav :class="classObject">
    <ul class="navigation__list">
      <li class="navigation__item" v-for="item in content" :key="item.id">
        <a class="navigation__link" v-scroll-to="item.url" href="#" v-if="isUrl(item.url)">{{ item.title }}</a>
        <a class="navigation__link" :href="item.url" target="_blank" v-else>{{ item.title }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["parentClass", "content"],
  data() {
    return {
      classObject: {
        navigation: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
  methods: {
    isUrl(url) {
      if (url != "") return url[0] === "#";
    },
  },
};
</script>

<style>
.navigation__list {
  display: flex;
  align-items: center;
}

.navigation__item {
  position: relative;

  margin-right: 50px;
}

.navigation__item:last-child {
  margin-right: 0;
}

.navigation__item::after {
  content: "";

  position: absolute;
  left: calc(50% - 6px);
  bottom: -37px;

  width: 11px;
  height: 11px;
  border-radius: 50%;

  background-color: var(--main-menu-color-white-hover);

  transform: scale(0);
  transition: transform 0.4s ease;
}

.navigation__item:hover::after {
  transform: scale(1);
}

.navigation__link {
  display: block;
  padding: 8px 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  color: var(--main-menu-color-white);

  transition: color 0.5s ease;
}

.navigation__link:hover {
  color: var(--main-menu-color-white-hover);
}

@media (max-width: 1599.98px) {
  .navigation__item {
    margin-right: 40px;
  }

  .navigation__item::after {
    content: "";

    position: absolute;
    left: calc(50% - 5px);
    bottom: -25px;

    width: 10px;
    height: 10px;
  }

  .navigation__link {
    padding: 6px 0;

    font-size: 12px;
  }
}
</style>
