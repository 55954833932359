<template>
  <div class="card-partners" :class="{'card-partners--reverse': reverse}">
    <div class="container card-partners__container">
      <div class="card-partners__col-1">
        <div class="card-partners__inner">
          <h3 class="card-partners__title" v-if="content.title" v-html="content.title"></h3>
          <p class="card-partners__desc" v-if="content.desc" v-html="content.desc"></p>
          <Button parent-class="card-partners__button" @click="$emit('popup')" v-if="content.button">{{ content.button}}</Button>
        </div>
      </div>
      <div class="card-partners__col-2">
        <img class="card-partners__img" width="975" height="490" :src="content.img.sizes.partners_full" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    Button
  },
  props: ["content", "reverse"],
  emits: ["popup"]
};
</script>

<style>
.card-partners {
  background-color: var(--backgraund-site-dark);
}

.card-partners__container.container {
  display: flex;
  flex-direction: row-reverse;
  max-width: 1920px;
}

.card-partners--reverse .card-partners__container {
  flex-direction: row;
}

.card-partners__col-1 {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  padding: 50px 0;
  padding-left: 120px;
}

.card-partners--reverse .card-partners__col-1 {
  justify-content: flex-end;
  padding-right: 240px;
}

.card-partners__inner {
  width: 450px;
}

.card-partners__title {
  margin-bottom: 20px;

  font-family: var(--title3-fonts-family);
  font-size: var(--title3-fonts-size);
  line-height: var(--title3-line-height);
  color: var(--title3-color-dark);
  font-weight: 400;
}

.card-partners__desc {
  display: -webkit-box;
  margin-bottom: 30px;

  font-family: var(--subtitle3-fonts-family);
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  color: var(--subtitle3-color-dark);
  font-weight: 400;

  overflow: hidden;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card-partners__button {
  font-size: 18px;
  padding: 20px 70px;
}

.card-partners__col-2 {
  flex: 0 0 50%;
}

.card-partners__img {
  width: 100%;
  height: auto;
}

@media (max-width: 1919.98px) {
  .card-partners__col-1 {
    padding: 40px 0;
    padding-left: 80px;
  }

  .card-partners--reverse .card-partners__col-1 {
    padding-right: 200px;
  }

  .card-partners__inner {
    width: 410px;
  }

  .card-partners__button {
    padding: 18px 65px;
  }
}

@media (max-width: 1599.98px) {
  .card-partners__col-1 {
    padding: 30px 0;
    padding-left: 50px;
  }

  .card-partners--reverse .card-partners__col-1 {
    padding-right: 50px;
  }

  .card-partners__inner {
    width: 450px;
  }

  .card-partners__title {
    margin-bottom: 15px;
  }

  .card-partners__desc {
    margin-bottom: 20px;
  }

  .card-partners__button {
    font-size: 16px;
    padding: 16px 60px;
  }
}

@media (max-width: 1199.98px) {
  .card-partners__col-1 {
    padding-left: 40px;
  }

  .card-partners--reverse .card-partners__col-1 {
    padding-right: 40px;
  }

  .card-partners__inner {
    width: 370px;
  }

  .card-partners__button {
    font-size: 16px;
    padding: 16px 50px;
  }

  .card-partners__title {
    font-size: 24px;
  }

  .card-partners__desc {
    font-size: 14px;
  }

  .card-partners__button {
    padding: 12px 50px;
  }
}

@media (max-width: 991.98px) {
  .card-partners {
    background-color: transparent;
  }

  .card-partners__container.container {
    flex-direction: column;
    max-width: 592px;
  }

  .card-partners--reverse .card-partners__container {
    flex-direction: column;
  }

  .card-partners__col-1 {
    flex: 0 0 100%;
    padding: 40px;

    background-color: var(--backgraund-site-dark);
  }

  .card-partners--reverse .card-partners__col-1 {
    padding: 40px;
  }

  .card-partners__inner {
    width: 100%;
  }

  .card-partners__button {
    font-size: 14px;
    padding: 15px 40px;
  }

  .card-partners__col-2 {
    flex: 0 0 100%;
  }
}

@media (max-width: 768.98px) {
  .card-partners__col-1 {
    padding: 30px;
  }

  .card-partners--reverse .card-partners__col-1 {
    padding: 30px;
  }

  .card-partners__button {
    padding: 12px 30px;
  }
}
</style>