<template>
  <li :class="classObject" v-on:click="openPopup()">
    <div class="card-service__icon">
      <img class="card-service__img" v-lazy="content.services_list_id_img.url"
        :src="content.services_list_id_img.url" v-if="content.services_list_id_img.url" alt="" />
    </div>
    <div class="card-service__info">
      <div class="card-service__content">
        <H3 parent-class="card-service__name" v-if="content.services_list_name" v-html="content.services_list_name">
        </H3>
        <div class="card-service__price">
          <span class="card-service__price-main" v-if="content.services_list_price"
            v-html="content.services_list_price"></span>
          <span class="card-service__price-old" v-if="content.services_list_price_old">{{
          content.services_list_price_old }}</span>
        </div>
      </div>
      <Button parent-class="card-service__button" v-if="content.services_list_button">{{ content.services_list_button
      }}</Button>
    </div>
  </li>
</template>

<script>
import H3 from "@/components/element/H3App.vue";
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    H3,
    Button,
  },
  props: ["parentClass", "content"],
  data() {
    return {
      classObject: {
        ["card-service"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
  methods: {
    openPopup() {
      if (this.content.services_list_button_metka) {
        this.$metrika.reachGoal(this.content.services_list_button_metka);
      }

      this.$store.commit("updateServicesPopup", {
        status: true,
        data: this.content,
      });
    },
  },
};
</script>

<style>
.card-service {
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;

  background-color: #dfdfdf;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  cursor: pointer;
}

.card-service__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
}

.card-service__img {
  width: 100%;
  height: 100%;

  object-position: top;
  object-fit: cover;
  transition: transform 0.5s ease-in;
}

.card-service:hover .card-service__img {
  transform: scale(1.1);
}

.card-service__info {
  position: absolute;
  bottom: 0;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: calc(100% - 40px);
  min-height: 102px;
  padding: 5px 15px;
  margin: 0 auto;

  text-align: center;

  background-color: #ffffff;

  transform: translateX(-50%);
}

.card-service__info::before,
.card-service__info::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;

  width: 40px;
  height: 100%;

  background-color: #ffffff;
  transition: transform 0.5s ease-in;
}

.card-service__info::before {
  left: 0;
}

.card-service__info::after {
  right: 0;
}

.card-service:hover .card-service__info::before {
  transform: translateX(-30px);
}

.card-service:hover .card-service__info::after {
  transform: translateX(30px);
}

.card-service__content {
  opacity: 1;
  transition: opacity 0.3s linear 0.2s;
}

.card-service:hover .card-service__content {
  opacity: 0;
  transition-delay: 0s;
}

.card-service__name {
  margin-bottom: 5px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;

  font-family: var(--title4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title4-fonts-size);
  line-height: 1.4;
  color: var(--title4-color-white);

  overflow: hidden;
}

.card-service__price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card-service__price-main {
  font-family: var(--title5-fonts-family);
  font-size: var(--title5-fonts-size);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: var(--title5-color-white);
  margin: 0 10px 0 10px;
}

.card-service__price-old {
  position: relative;

  margin: 0 10px 0 10px;

  font-family: var(--subtitle4-fonts-family);
  font-size: var(--subtitle4-fonts-size);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: var(--subtitle4-color-white);
}

.card-service__price-old:after {
  content: "";

  position: absolute;
  top: 50%;
  left: 0;

  width: 100%;
  height: 1px;

  background-color: var(--title3-color-white);
}

.card-service__button {
  position: absolute;

  opacity: 0;
  transition: opacity 0.3s linear;
}

.card-service:hover .card-service__button {
  opacity: 1;
  transition-delay: 0.4s;
}

@media (max-width: 1599.98px) {
  .card-service__icon {
    height: 336px;
  }

  .card-service__info {
    width: calc(100% - 30px);
    min-height: 74px;
    padding: 15px 10px;
  }

  .card-service__info::before,
  .card-service__info::after {
    width: 30px;
  }

  .card-service:hover .card-service__info::before {
    transform: translateX(-20px);
  }

  .card-service:hover .card-service__info::after {
    transform: translateX(20px);
  }

  .card-service__name {
    margin-bottom: 3px;

    font-size: 16px;
  }

  .card-service__price-main {
    font-size: 18px;
  }
}

@media (max-width: 1199.98px) {
  .card-service__icon {
    height: 234px;

    overflow: hidden;
  }

  .card-service__info {
    position: static;

    flex-direction: column;
    width: 100%;

    transform: translateX(0);
  }

  .card-service__info::after,
  .card-service__info::before {
    display: none;
  }

  .card-service:hover .card-service__content {
    opacity: 1;
  }

  .card-service__name {
    margin-bottom: 8px;

    font-size: 14px;
  }

  .card-service__price {
    margin-bottom: 8px;

    font-size: 16px;
  }

  .card-service__button {
    position: relative;

    margin-top: auto;

    opacity: 1;
  }
}

@media (max-width: 991.98px) {
  .card-service__icon {
    height: 306px;
  }

  .card-service__info {
    min-height: 113px;
  }
}

@media (max-width: 767.98px) {
  .card-service__icon {
    height: 260px;
  }

  .card-service__info {
    min-height: 108px;
  }

  .card-service__price-old {
    margin-left: 15px;
    font-size: 12px;
  }
}

@media (max-width: 575.98px) {
  .card-service__icon {
    height: 240px;
  }

  .card-service__price {
    flex-direction: column;
  }
}

@media (max-width: 532.98px) {
  .card-service__icon {
    height: 200px;
  }
}

@media (max-width: 424.98px) {
  .card-service__icon {
    height: 160px;
  }
}

@media (max-width: 359.98px) {
  .card-service__icon {
    height: 140px;
  }
}
</style>
